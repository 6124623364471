"use client";
import React from "react";
import { AspectRatio } from "~/components/ui/aspect-ratio";
import Image from "next/image";
import { Button } from "~/components/ui/button";
import { ArrowLongRight } from "~/components/icons";
import { LinkTrackers } from "~/components/reusables/link-trackers";
import { usePathname } from "next/navigation";
import StrapiBlocksRenderer from "~/components/reusables/strapi-blocks-renderer";

export function BannerClient({ banner, btn, blogs }) {
  const pathname = usePathname();
  const showBanner =
    pathname == "/" ||
    pathname == "/blog/topic/panoptica" ||
    blogs.includes(pathname);

  return (
    <>
      {showBanner && (
        <div className="bg-gradient-to-r from-[#06144D] to-[#005CAB] py-5 text-white md:py-6">
          <div className="container flex min-h-14 items-center gap-3">
            <div className="self-start">
              <div className="h-[42px] w-[39px] md:mr-6 mt-2">
                <AspectRatio ratio={39 / 42}>
                  <Image
                    src={
                      "https://outshift-headless-cms-s3.s3.us-east-2.amazonaws.com/panoptica-logo-reversed.svg"
                    }
                    alt="Panoptica logo"
                    width={39}
                    height={42}
                    unoptimized
                  ></Image>
                </AspectRatio>
              </div>
            </div>
            {banner.description && (
              <StrapiBlocksRenderer content={banner.description} />
            )}
            <Button variant="ghost" className="px-0 md:px-3" asChild>
              <LinkTrackers
                segmentMsg="Banner Link Clicked"
                segmentOpt={{
                  section_clicked: "Sunset Annoucement Banner",
                }}
                href={btn.url}
                target="_blank"
                aria-label="Learn more about Panopica"
                className="lg:font-btn flex items-center gap-1 text-xs"
              >
                {btn.cta && <p className="hidden md:block">{btn.cta}</p>}
                <div className="ml-1">
                  <ArrowLongRight size={30} />
                </div>
              </LinkTrackers>
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
